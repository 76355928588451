<template>
  <CRow class="h-100">
    <vue-element-loading
      :active="loading"
      :text="loadingText"
      spinner="bar-fade-scale"
      color="var(--secondary)"
    />
    <CCol md="12">
      <div class="main-card h-100">
        <CCallout color="info">
          <strong class="h5">اطلاعات کیف پول {{ accountInfo.fullName }}</strong>
          <br/>
          <small v-if="accountInfo.state" class="text-success"
          >کیف پول شما فعال می باشد</small
          >
          <small v-else class="text-denger">کیف پول شما مسدود است</small>
        </CCallout>
        <CRow>
          <CCol md="6" style="padding: 0 1px 0 2px">
            <div class="infoBox">
              <label>شماره کیف پول:</label>
              <h6>{{ accountInfo.number }}</h6>
            </div>
            <div class="infoBox">
              <label>موجودی:</label>
              <h6>{{ accountInfo.inventory }} ریال</h6>
            </div>
<!--            <div class="infoBox">-->
<!--              <label>هزینه سفارشی:</label>-->
<!--              <h6>-->
<!--                {{-->
<!--                  accountInfo.customServiceAmount == null-->
<!--                    ? "-&#45;&#45;"-->
<!--                    : accountInfo.customServiceAmount-->
<!--                }}-->
<!--              </h6>-->
<!--            </div>-->
<!--            <div class="infoBox">-->
<!--              <label>تعداد تراکنش:</label>-->
<!--              <h6>{{ accountInfo.transactionCount }} عدد</h6>-->
<!--            </div>-->
          </CCol>
          <CCol md="6" style="padding: 0 1px 0 2px">
<!--            <div class="infoBox">-->
<!--              <label>نوع آخرین تراکنش:</label>-->
<!--              <h6>{{ accountInfo.lastModifyType }}</h6>-->
<!--            </div>-->
            <div class="infoBox">
              <label>تاریخ آخرین تراکنش:</label>
              <h6 style="direction: ltr">{{ accountInfo.lastModifyDate }}</h6>
            </div>
<!--            <div class="infoBox">-->
<!--              <label>نوع هزینه خدمات:</label>-->
<!--              <h6-->
<!--                :style="-->
<!--                  accountInfo.customServiceAmount != null-->
<!--                    ? 'font-size: 9px;background-color: #bbb;'-->
<!--                    : ''-->
<!--                "-->
<!--              >-->
<!--                {{ accountInfo.userTypeCostTitle }}-->
<!--              </h6>-->
<!--            </div>-->
            <div class="infoBox">
              <label>هزینه خدمات:</label>
              <h6
                class="text-nowrap"
                :style="
                  accountInfo.customServiceAmount != null
                    ? 'font-size: 9px;background-color: #bbb;'
                    : ''
                "
              >
                {{ accountInfo.userTypeCostAmount }}
                {{
                  accountInfo.customServiceAmount == null
                    ? "ریال به ازای هر هکتار"
                    : ""
                }}
              </h6>
            </div>
          </CCol>
        </CRow>
      </div>
    </CCol>
  </CRow>
</template>

<script>

import {mapActions} from "vuex";

export default {
  name: "accountInfoAndLatestTransaction",
  components: {},
  data() {
    return {
      loading: false,
      loadingText: "",
      transactionState: true,
      accountInfo: {
        accountId: 0,
        fullName: "",
        state: false,
        number: "",
        inventory: "",
        customServiceAmount: "",
        transactionCount: 0,
        lastModifyType: "",
        lastModifyDate: "",
        userTypeCostTitle: "",
        userTypeCostAmount: "",
      },
      latestIncreaseInfo: {
        amount: 0,
        dateTime: "",
        trackingCode: "",
        bankInfo: "",
        description: "",
      },
      latestDecreaseInfo: {
        amount: 0,
        dateTime: "",
        trackingCode: "",
        bankInfo: "",
        description: "",
      },
    };
  },
  methods: {
    ...mapActions("financialUser", [
      "UserAccountInfo",
      "UserLatestTransaction",
    ]),
    async getFinancialAccountInfo() {
      this.loading = true;
      this.loadingText = "دریافت اطلاعات کیف پول شما";
      let result = await this.UserAccountInfo({});
      if (result.succeeded) {
        this.accountInfo.accountId = result.data.financialAccountId;
        this.$emit("setAccountId", this.accountInfo.accountId);
        this.accountInfo.fullName = result.data.userFullName;
        this.accountInfo.state = result.data.state;
        this.accountInfo.number = result.data.number;
        this.accountInfo.inventory = result.data.inventory;
        this.accountInfo.customServiceAmount = result.data.customServiceAmount;
        this.accountInfo.transactionCount = result.data.transactionCount;
        this.accountInfo.lastModifyType = result.data.lastModifyType;
        this.accountInfo.lastModifyDate = result.data.lastModifyDate;
        this.accountInfo.userTypeCostTitle = result.data.customServiceAmount != null ? "هزینه خدمات شما سفارشی محاسبه میشود" : result.data.userTypeCostTitle;
        this.accountInfo.userTypeCostAmount = result.data.customServiceAmount != null ? "هزینه خدمات شما سفارشی محاسبه میشود" : result.data.userTypeCostAmount;
        this.loading = false;
        // await this.getLatestAccountTransactionInfo();
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: `${result.message} <br /><br />`,
          type: "error",
        });
      }
    },
    async getLatestAccountTransactionInfo() {
      this.loadingText = "دریافت آخرین تراکنش های مالی";
      let result = await this.UserLatestTransaction({
        accountId: this.accountInfo.accountId,
      });
      if (result.succeeded) {
        this.latestIncreaseInfo.amount = result.data.increaseAmount;
        this.latestIncreaseInfo.dateTime = result.data.increaseCreateDateTime;
        this.latestIncreaseInfo.trackingCode = result.data.increaseTrackingCode;
        this.latestIncreaseInfo.bankInfo = result.data.increaseBankInfo;
        this.latestIncreaseInfo.description = result.data.increaseDescription;

        this.latestDecreaseInfo.amount = result.data.decreaseAmount;
        this.latestDecreaseInfo.dateTime = result.data.decreaseCreateDateTime;
        this.latestDecreaseInfo.trackingCode = result.data.decreaseTrackingCode;
        this.latestDecreaseInfo.bankInfo = result.data.decreaseBankInfo;
        this.latestDecreaseInfo.description = result.data.decreaseDescription;
      } else {
        this.transactionState = false;
        this.$notify({
          title: "پیام سیستم",
          text: `${result.message} <br /><br />`,
          type: "info",
        });
      }
      this.loading = false;
    },
  },
  mounted() {
    this.getFinancialAccountInfo();

  },
};
</script>

<style scoped>

.infoBox {
  width: 100%;
  margin: 0 0 2px 0;
}

.infoBox label {
  text-align: right;
  height: inherit;
  line-height: 30px;
  padding-right: 2px;
  font-size: 0.79rem;
  font-weight: bold;
  margin: 0;
}

.infoBox h6 {
  text-align: right;
  background-color: #e9e9e9;
  height: inherit;
  padding-right: 7px;
  line-height: 30px;
  font-size: 0.8rem;
}

.tran_TrackingCode h1 {
  float: right;
  width: 100%;
  font-size: 1.4rem;
  margin: 0;
  text-align: center;
  line-height: 36px;
  height: 36px;
  background-color: #fff;
}

.tran_TrackingCode label {
  width: 100%;
  text-align: center;
  font-size: 0.8rem;
  margin: 0;
  font-weight: bold;
}

</style>
