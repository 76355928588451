<template>
  <div class="body-h-fix">
    <main-body-header title="شارژ کیف پول (با شارژ کیف پول می توانید هزینه خدمات را از موجودی کیف پول خود پرداخت کنید)"/>
    <div class="body-wrapper">
      <div class="light-1"></div>
      <div class="light-2"></div>
      <CRow align-horizontal="center">
        <CCol col="12" lg="6" xxl="5">
          <accountInfoAndLatestTransaction
            @setAccountId="setCurrentAccountId"
            ref="accountInfoAndLatestTransactionRef"
          />
        </CCol>
        <CCol col="12" lg="5" xxl="5" class="p-0">
          <accountIncreaseInventory :currentAccountId="accountId" @refreshForm="refreshAllAccountInfo"/>
        </CCol>
      </CRow>
    </div>
  </div>
</template>

<script>

import accountInfoAndLatestTransaction from "./accountInfoAndLatestTransaction.vue";
import accountIncreaseInventory from "./accountIncreaseInventory.vue";
import MainBodyHeader from "../../../components/mainHeader.vue";

export default {
  components: {
    MainBodyHeader,
    accountInfoAndLatestTransaction,
    accountIncreaseInventory
  },
  data() {
    return {
      accountId: 0,
      routtransactionId: null,
      routauthority: null,
      routStatus: null,
      transactionId: 0
    };
  },
  methods: {
    setCurrentAccountId(id) {
      this.accountId = id;
    },
    async refreshAllAccountInfo() {
      await this.$refs.accountInfoAndLatestTransactionRef.getFinancialAccountInfo();
      this.$root.$on("TheHeader", () => {
        this.getCurrentUserFinancialAccountInventory();
      });
      // this.$router.replace({
      //     name: "userAccountManagement",
      //     query: null //{ transactionId: "", Authority: "" , Status : "" }
      //   });
    }
  },
  mounted() {},
  created() {}
};
</script>

<style scoped>

.infoBox label {
  float: right;
  clear: right;
  width: 40%;
  background-color: #d1d1d1;
  text-align: right;
  height: inherit;
  line-height: 30px;
  padding-right: 2px;
  font-size: 0.79rem;
  font-weight: bold;
}

.infoBox h6 {
  width: 60%;
  float: right;
  clear: left;
  text-align: right;
  background-color: #e9e9e9;
  height: inherit;
  padding-right: 7px;
  line-height: 30px;
  font-size: 0.8rem;
}
</style>
